// $assetPathStorybook: '/assets/';
$assetPath: '/assets/';

$breakpoints: (
    xs: 375px,
    s: 768px,
    m: 1020px,
    l: 1280px,
    xl: 1440px,
    xxl: 1680px,
    vitec-s: 556px,
    vitec-m: 1020px,
);

$maxwidths: (
    xs: 345px,
    s: 720px,
    m: 1000px,
    l: 1140px,
    xl: 1260px,
    xxl: 1440px,
    content: 950px,
    content-l: 725px,
);

$colorBlue: #4d7aa1;
$colorBlue900: #0e2465;
$colorBlue100: #c1c8e1;
$colorBlue50: #e6e9f2;

$colorGreen: #45852a;
$colorGreen900: #166b17;
$colorGreen100: #d7eec7;
$colorGreen50: #eff8e9;

$colorYellow: #fecb00;
$colorYellow100: #ffedb0;
$colorYellow50: #fff8e0;
$colorYellowSecondary: #fed543;

$colorBeige: #e6c6af;
$colorBeige900: #823200;
$colorBeige50: #f2e6e1;

$colorRed: #e3757e;
$colorRed900: #b91b2b;
$colorRed100: #fdced7;
$colorRed50: #feebf0;
$colorErrorMessage: #7a131d;

$colorLightBlue: #95b7db;
$colorLightBlue100: #bcd2ea;
$colorLightBlue50: #e3edf6;

$colorBlack: #212121;
$colorBlack700: #727272;
$colorBlack600: #757575;
$colorBlack500: #9e9e9e;
$colorBlack400: #bdbdbd;
$colorBlack300: #e0e0e0;
$colorBlack200: #eeeeee;
$colorBlack100: #f5f5f5;

//New colors
$colorAshGrey20: #e1dedd;
$colorAshGrey40: #c2beba;
$colorAshGrey60: #a49d98;
$colorAshGrey80: #857d75;
$colorAshGrey: #675c53;

$newColorBlue20: #dde6ee;
$newColorBlue40: #bbcddd;
$newColorBlue60: #98b4cd;
$newColorBlue80: #769bbc;
$newColorBlue: #4d7aa1;

$fontOpenSans: 'Calibri', sans-serif;
$fontRaleway: 'Calibri', sans-serif;

// Transitions
$duration: 0.3s;
$ease: ease-in-out;
$transition: $duration $ease;

@import 'mixins';
@import 'extensions';
