@import 'styles/includes.scss';

.RentalTag {
    @extend %labels-small;
    color: $colorBlack;
    box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: 9;
    right: 0;
    top: 16px;
    font-weight: bold;
    padding: 12px;

    &--ongoing {
        background-color: #e8d3a2;
    }

    &--planned {
        background-color: #d6a5ad;
    }

    &--done {
        background-color: #bbc6b5;
    }
}
