@import 'styles/includes.scss';

.CardListHalf {
    @extend %container;

    padding-top: 30px;

    @include media(m) {
        padding-top: 60px;
    }

    &__TopContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: end;
        margin-bottom: 30px;
    }

    &__Title {
        @extend %h2;
        color: $colorBlack;
        font-family: $fontRaleway;

        &::after {
            background-color: #557045;
            content: '';
            display: block;
            width: 100%;
            height: 5px;
            margin-top: 10px;
        }
    }

    &__Title--Blue {
        &::after {
            background-color: $newColorBlue;
            content: '';
            display: block;
            width: 100%;
            height: 5px;
            margin-top: 10px;
        }
    }

    &__Items {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 2.4rem;

        @include media(s) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media(m) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
