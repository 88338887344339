@import 'styles/includes.scss';

.Card {
    $root: &;

    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    background: #fff;
    text-decoration: none;
    transition: all $transition;
    color: $colorBlack;

    @include media(s) {
        flex-flow: row;
    }

    &--100 {
        @include media(s) {
            min-height: 272px;
        }

        #{$root}__Content {
            justify-content: flex-start;
        }
    }

    &--50 {
        width: 100%;

        @include media(m) {
            width: calc(50% - 24px);
            min-height: 229px;

            &:last-child {
                margin-right: 0;
            }
        }

        #{$root}__Text {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        #{$root}__Content {
            margin: 0;
            padding: 32px 24px;
            justify-content: flex-start;
        }
    }

    &--25 {
        width: 100%;
        margin: 0;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;

        @include media(s) {
            width: calc(50% - 24px);
        }

        @include media(m) {
            width: calc(33% - 24px);
        }

        @include media(l) {
            width: calc(25% - 24px);
        }

        #{$root}__Image {
            height: 144px;
            min-height: 144px;
            width: 100%;
        }

        #{$root}__Content {
            @extend %body-copy-small;
            justify-content: space-between;
            width: 100%;
            margin: 0;
            padding: 32px 24px;
            height: 100%;
        }

        #{$root}__Text {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    &--Rental {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        position: relative;
        text-align: left;
        text-decoration: none;

        &:hover {
            text-decoration: none;

            #{$root}__Title {
                text-decoration: underline;
            }
        }

        &:nth-child(3) {
            display: none;
        }

        @include media(m) {
            &:nth-child(3) {
                display: block;
            }
        }

        #{$root}__Image {
            min-height: 180px;
            height: 180px;
            width: 100%;

            img {
                left: 0;
            }

            @include media(m) {
                min-height: 204px;
                height: 204px;
            }
        }

        #{$root}__Content {
            display: flex;
            flex-direction: column;
            color: $colorBlack;
            text-decoration: none;
            text-align: left;
            padding: 16px 16px 32px;
            text-decoration: none;
            width: 100%;

            @include media(m) {
                padding: 16px 16px 54px 16px;
            }
        }

        #{$root}__ContentInner {
            display: flex;
            flex-direction: column;
        }

        #{$root}__InfoLabel {
            display: inline-block;
            font-family: $fontRaleway;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
            color: $colorBlack;
            width: 100%;
        }

        #{$root}__Title {
            @extend %h3;
            margin-bottom: 4px;
            position: relative;
            display: inline-block;
            margin-right: 0;
            width: fit-content;

            @include media(m) {
                margin-top: 5px;
                margin-bottom: 10px;
            }
        }
    }

    &--Area {
        flex-flow: column;
        width: 100%;
        display: block;
        transition: all $transition;

        @include media(s) {
            width: 100%;
        }

        @include media(m) {
            width: 100%;
        }

        &:hover {
            text-decoration: underline;
            box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.1);
        }
    }

    &__Image {
        height: 280px;
        width: 100%;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
        }

        @include media(s) {
            min-height: 100%;
            height: 272px;
            height: initial;
            width: calc(50% - 25px);
        }

        #{$root}--Area & {
            width: 100%;
            max-height: 144px;
            min-height: 144px;
        }

        // #{$root}--Rental & {
        //     max-height: 144px;
        //     min-height: 144px;
        // }
    }

    &__Content {
        width: 100%;
        padding: 16px 16px 32px 16px;
        display: flex;
        flex-flow: column;
        justify-content: center;

        @include media(m) {
            padding: 48px 25px 48px 0;
        }

        h3 {
            @extend %h3;
            padding-bottom: 10px;
            position: relative;
        }

        @include media(s) {
            width: 50%;
        }

        #{$root}--Area & {
            width: 100%;
            padding: 30px 24px 73px;
            margin-left: 0;

            #{$root}__Text {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }

    &__Title {
        @extend %h2;
        display: inline-block;
        position: relative;
        margin-right: 20px;
        margin-bottom: 16px;

        @include media(s) {
            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 8px;
                right: -20px;
                background-image: url('#{$assetPath}svg/arrow--right-black.svg');
                background-repeat: no-repeat;
                width: 7px;
                height: 12px;
                background-size: contain;
            }
        }
    }

    &__InfoLabel {
        @extend %body-copy-small;
        display: block;
        font-weight: normal;
    }

    &__Text {
        @extend %body-copy-small;
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }

    &__Button {
        margin: 34px auto 0;

        @include media(m) {
            margin-top: 40px;
            margin-left: 0;
        }
    }

    &--CardListHalf {
        margin: 0;
        grid-column: span 1;

        justify-content: start;
        flex-direction: column;

        #{$root}__Image {
            min-height: 204px;
            height: 204px;
            width: 100%;

            img {
                left: 0;
            }

            @include media(m) {
                min-height: 404px;
                height: 100%;
                width: 404px;
                min-width: 404px;
            }
        }

        #{$root}__Content {
            padding: 1.6rem;
            display: flex;
            flex-direction: column;
            width: 100%;

            @include media(m) {
                padding: 1.6rem 3.2rem 1.6rem 1.6rem;
            }
        }

        #{$root}__Button {
            margin: 34px auto 0;

            @include media(m) {
                margin-top: 29px;
                margin-left: 0;
            }
        }

        @include media(m) {
            grid-column: span 2;
            flex-direction: row;
        }
    }

    &--IsEven {
        grid-column: span 1;
        justify-content: start;
        flex-direction: column;

        #{$root}__Image {
            min-height: 204px;
            height: 204px;
            width: 100%;
            min-width: 0 !important;

            img {
                left: 0;
            }
        }

        #{$root}__Content {
            height: 100%;
            padding: 1.6rem;
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        #{$root}__Button {
            margin: 34px auto 0;

            @include media(m) {
                margin-top: 20px;
                margin-left: 0;
            }
        }
    }

    &__Arrow {
        position: absolute;
        height: 25px;
        width: 25px;
        right: 2.2rem;
        bottom: 1.6rem;
        transition: all $transition;

        #{$root}:hover & {
            right: 1.6rem;
        }
    }
}
